import 'bootstrap';
import AccountDetailsOverview from 'components/advisor/accounts/details/view/account-details-overview';
import AccountDetailsTolerance from 'components/advisor/accounts/details/view/account-details-tolerance';
import ChangePasswordForm from 'components/advisor/change-password-form';
import InvestorDetailsTolerance from 'components/advisor/investors/details/investor-details-tolerance';
import InvestorOverview from 'components/advisor/investors/details/overview';
import InvestorPortfolioInsights from 'components/advisor/investors/details/portfolio-insights';
import InvestorPrism from 'components/advisor/investors/details/prism-investor';
import InvestorDetailsNotes from 'components/advisor/investors/investor-details-notes';
import InvestorsAccountList from 'components/advisor/investors/investors-account-list/index';
import IPS from 'components/advisor/ips';
import AdvisorModelsDetail from 'components/advisor/models/detail';
import Proposal from 'components/advisor/proposal';
import {
  INVESTOR_PROPOSAL_TYPE,
  MODEL_PROPOSAL_TYPE,
  PROSPECT_PROPOSAL_TYPE,
  SECURITY_PROPOSAL_TYPE
} from 'components/advisor/proposal/constants';
import { PERMISSION_STAFF } from 'constants/actions/auth';
import Advisor from 'containers/advisor/';
import AccountDetails from 'containers/advisor/account-details';
import AccountsByRiskLevel from 'containers/advisor/accounts-by-risk-level';
import AIAssistant from 'containers/advisor/ai-assistant';
import ScanIQ from 'containers/advisor/ai-assistant/scan-iq';
import ScanDetails from 'containers/advisor/ai-assistant/scan-iq/details';
import ScanHistory from 'containers/advisor/ai-assistant/scan-iq/scan-history';
import ClientsWrapper from 'containers/advisor/clients';
import ComplianceSurveillance from 'containers/advisor/compliance-surveillance';
import ConnectAccount from 'containers/advisor/connect-account';
import AdvisorDashboard from 'containers/advisor/dashboard';
import DriftExceptions from 'containers/advisor/drift-exceptions';
import Household from 'containers/advisor/household';
import Households from 'containers/advisor/households';
import InvestorPrismGenerate from 'containers/advisor/investor-prism-generate';
import AdvisorInvestors from 'containers/advisor/investors';
import InvestorsAccount from 'containers/advisor/investors-account';
import CompanyProfile from 'containers/advisor/me-company';
import AdvisorModelAccessControl from 'containers/advisor/model-access-control';
import AdvisorModelsDetailsOverview from 'containers/advisor/model-details-overview';
import AdvisorModelStrategy from 'containers/advisor/model-portfolio-strategy';
import AdvisorModels from 'containers/advisor/models';
import OnboardingChecklist from 'containers/advisor/onboarding-checklist';
import RiskAnalysis from 'containers/advisor/risk-analysis';
import AdvisorSecurity from 'containers/advisor/security';
import TwoFactor from 'containers/advisor/security/two-factor';
import App from 'containers/app';
import SignupForm from 'containers/auth/signup-form';
import EmailVerification from 'containers/auth/verification';
import EmailVerificationSent from 'containers/auth/verification-sent';
import MarketingBanner from 'containers/guest/marketing-banner';
import PrismQuestionsGuest from 'containers/guest/prism-questions-guest';
import Investor, { InvestorSessionRedirect } from 'containers/investor';
import MarketplaceDetail from 'containers/marketplace/details';
import MarketplaceDetailAbout from 'containers/marketplace/details/about';
import CustomSecurityBulkTable from 'containers/security/bulk-table';
import CustomSecurityCreate from 'containers/security/create';
import CustomSecurityDetail from 'containers/security/details';
import SecurityAccessControl from 'containers/security/details/access-control';
import SecurityOverview from 'containers/security/details/overview';
import SecurityReturns from 'containers/security/details/returns';
import SecurityRiskReturns from 'containers/security/details/risk-returns';
import SecurityUnderlyingHolding from 'containers/security/details/underlying-holding';
import CustomSecurityList from 'containers/security/list';
import CompanyEditable from 'containers/staff-companies/editable-staff-company';
import CompanyTable from 'containers/staff-companies/staff-companies-list';
import AdvisorEditable from 'containers/staff-companies/advisors/editable';
import StandardContainer from 'containers/standard-container';
import StyleGuide from 'containers/style-guide';
import AccessEndorsements from 'pages/access-endorsements';
import AccountDetailsPrism from 'pages/account-details-prism';
import Billing from 'pages/billing';
import CheckInQuestionnaire from 'pages/check-in-questionnaire';
import CheckIns from 'pages/check-ins';
import Checkout from 'pages/checkout';
import CheckoutPayment from 'pages/checkout/payment';
import CheckoutPrices from 'pages/checkout/prices';
import CollaborationHub from 'pages/collaboration-hub';
import CompanyUsers from 'pages/company-users';
import Configuration from 'pages/configuration';
import CreateNewClient from 'pages/create-new-client';
import FactFinderForms from 'pages/fact-finder-forms';
import IntegrationAccounts from 'pages/integration-accounts';
import IntegrationsDashboard from 'pages/integrations-dashboard';
import AvailableIntegrations from 'pages/integrations-dashboard/available';
import ConnectedIntegrations from 'pages/integrations-dashboard/connected';
import ModelsMarketplace from 'pages/models-marketplace';
import PasswordReset from 'pages/password-reset';
import Profile from 'pages/profile';
import ProposalOrIpsReports from 'pages/proposal-or-ips-reports';
import { IPS_REPORT_TYPE, PROPOSAL_REPORT_TYPE } from 'pages/proposal-or-ips-reports/constants';
import ToleranceQuestionnaire from 'pages/risk-tolerance-questionnaire';
import SignatureRequest from 'pages/signature-request';
import Signin from 'pages/signin';
import Signup from 'pages/signup';
import Teams from 'pages/teams';
import Templates from 'pages/templates';
import PropTypes from 'prop-types';
import React from 'react';
import { IndexRedirect, IndexRoute, Route, Router } from 'react-router';
import DevelopmentReportViewer from 'reports/development-viewer';
import {
  hasCompanySettings,
  hasCompanyStatus,
  hasPermission,
  isComplianceOrAbove,
  isComplianceOrAboveSingleRout,
  onEnterHooks
} from 'utils/router';
import { trackHubSpotPageView } from 'utils/tracking';
import Supervisors from './pages/supervisors';
import { setPageTitleFromPath } from './seo';

const SHARE_YOUR_CALENDAR = 'share-your-calendar';

// handleOnUpdate function gets ReactClassComponent context (using this)
// instead of AppRouter parent component context
function handleOnUpdate() {
  const {
    location: { action, basename, hash, pathname }
  } = this.state;

  if (action === 'PUSH')
    if (hash && hash === `#${SHARE_YOUR_CALENDAR}`) {
      const element = document.getElementById(SHARE_YOUR_CALENDAR);
      if (element) element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    } else window.scrollTo(0, 0);

  if (action === 'PUSH' || action === 'REPLACE' || action === 'POP') {
    const path = `${basename}${pathname}`;
    setPageTitleFromPath(path);
    trackHubSpotPageView(path);
    window.refreshHubSpotWidget();
  }
}

const AppRouter = ({ history, store }) => (
  <Router history={history} onUpdate={handleOnUpdate}>
    <Route path="/" component={App}>
      <Route path="developers">
        <IndexRedirect to="style-guide" />
        <Route path="report-viewer" component={DevelopmentReportViewer} />
        <Route path="prism-generate" component={InvestorPrismGenerate} />
        <Route path="style-guide" component={StyleGuide} />
      </Route>
      <Route path="signin" component={Signin} />
      <Route path="signup" component={Signup}>
        <IndexRoute component={SignupForm} />
        <Route path="invitation/:invitationKey" component={SignupForm} />
        <Route path="verification/:verificationKey" component={EmailVerification} />
        <Route path="verification-sent" component={EmailVerificationSent} />
      </Route>
      <Route path="forgot-password" component={PasswordReset} />

      <Route
        path="checkout"
        onEnter={onEnterHooks(hasCompanyStatus(store, ['pending'], '/advisor'))}
      >
        <IndexRedirect to="plans" />
        <Route component={Checkout}>
          <Route path="plans" component={CheckoutPrices} />
          <Route path="payment" component={CheckoutPayment} />
        </Route>
      </Route>

      {/* Advisors */}
      <Route
        path="advisor"
        component={Advisor}
        onEnter={onEnterHooks(
          hasPermission(store, 'analyst'),
          hasCompanyStatus(store, ['active', 'trial'], '/checkout')
        )}
      >
        <IndexRedirect to="investors" />
        <Route component={StandardContainer}>
          <Route path="me">
            <IndexRedirect to="profile" />

            {/* Any advisor access */}
            <Route path="onboarding" component={OnboardingChecklist} />
            <Route path="security" component={AdvisorSecurity}>
              <IndexRedirect to="two-factor" />
              <Route path="two-factor" component={TwoFactor} />
              <Route path="change-password" component={ChangePasswordForm} />
            </Route>
            <Route path="profile" component={Profile}>
              <Route path=":target" />
            </Route>

            {/* Admin access */}
            <Route
              path="billing"
              component={Billing}
              onEnter={hasPermission(store, 'admin', '/advisor')}
            />

            {/* Advisor access */}
            <Route component={CollaborationHub}>
              <IndexRedirect to="teams" />
              <Route path="teams" component={Teams} />
              <Route path="access-endorsements" component={AccessEndorsements} />
              <Route path="supervisors" component={Supervisors} />
            </Route>

            {/* Manager access */}
            <Route
              path="users"
              component={CompanyUsers}
              onEnter={onEnterHooks(hasPermission(store, 'manager', '/advisor'))}
            />

            {/* Compliance access */}
            <Route
              path="company"
              component={CompanyProfile}
              onEnter={onEnterHooks(hasPermission(store, 'compliance', '/advisor'))}
            />
          </Route>
          <Route
            path=":id/profile"
            component={Profile}
            onEnter={onEnterHooks(hasPermission(store, 'manager', '/advisor'))}
          />
          <Route path="settings">
            <Route
              path="configurations"
              component={Configuration}
              onEnter={onEnterHooks(
                isComplianceOrAbove(
                  store,
                  hasPermission(store, 'compliance', '/advisor'),
                  hasCompanySettings(store, 'product', 'enterprise', '/advisor')
                )
              )}
            />
          </Route>
        </Route>
        <Route path="target-rating(/:s)" component={ToleranceQuestionnaire} flavor="advisor" />
        <Route
          path="templates"
          component={Templates}
          onEnter={onEnterHooks(
            isComplianceOrAbove(
              store,
              hasPermission(store, 'compliance', '/advisor'),
              hasCompanySettings(store, 'allow_advisors_creating_templates', true, '/advisor')
            )
          )}
        >
          <IndexRedirect to={isComplianceOrAboveSingleRout(store) ? 'crs' : 'ips'} />
          <Route path=":type" />
          <Route path=":type/:id" />
        </Route>
        <Route path="analytics" component={AdvisorDashboard} />
        <Route path="households">
          <IndexRoute component={Households} />
          <Route path=":id" component={Household} />
        </Route>
        <Route component={StandardContainer}>
          <Route
            path="integrations"
            component={IntegrationsDashboard}
            onEnter={onEnterHooks(
              hasCompanySettings(store, 'integrations_enabled', true, '/advisor')
            )}
          >
            <IndexRedirect to="available" />
            <Route path="available" component={AvailableIntegrations} />
            <Route path="connected" component={ConnectedIntegrations} />
          </Route>
        </Route>
        <Route
          component={StandardContainer}
          onEnter={onEnterHooks(hasPermission(store, PERMISSION_STAFF, '/advisor'))}
        >
          <Route path="companies" component={CompanyTable} />
          <Route path="companies/:companyId" component={CompanyEditable} />
          <Route path="companies/:companyId/advisor/:advisorId" component={AdvisorEditable} />
        </Route>
        <Route path="prospects">
          <IndexRoute component={AdvisorInvestors} type="prospect" />
          <Route path="create" component={CreateNewClient} />
          {/* prospect details */}
          <Route path=":id">
            <Route component={InvestorsAccount} isProspect>
              <IndexRedirect to="overview" /> {/* default tab */}
              <Route path="overview" component={InvestorOverview} />
              <Route path="check-ins" component={CheckIns} />
              <Route path="portfolio-insights" component={InvestorPortfolioInsights} />
              <Route path="prism" component={InvestorPrism} />
              <Route path="risk-tolerance" component={InvestorDetailsTolerance} />
              <Route path="fact-finder-forms" component={FactFinderForms} />
              <Route path="investment-objectives" component={InvestorsAccountList} />
              <Route path="proposals">
                <IndexRoute component={ProposalOrIpsReports} type={PROPOSAL_REPORT_TYPE} />
                <Route path="new" component={Proposal} type={PROSPECT_PROPOSAL_TYPE} />
                <Route path=":reportId" component={Proposal} type={PROSPECT_PROPOSAL_TYPE} />
              </Route>
              <Route path="ips">
                <IndexRoute component={ProposalOrIpsReports} type={IPS_REPORT_TYPE} />
                <Route path="new" component={IPS} />
                <Route path=":reportId" component={IPS} />
              </Route>
              <Route path="compliance-notes" component={InvestorDetailsNotes} />
            </Route>
            <Route path="target-rating(/:s)" component={ToleranceQuestionnaire} flavor="advisor" />
            <Route path="check-in(/:s)" component={CheckInQuestionnaire} flavor="advisor" />
            <Route path="account/:accountId">
              <Route component={AccountDetails}>
                <IndexRedirect to="overview" />
                <Route path="overview" component={AccountDetailsOverview} />
                <Route path="prism" component={AccountDetailsPrism} />
                <Route path="risk-tolerance" component={AccountDetailsTolerance} />
              </Route>
              <Route
                path="target-rating(/:s)"
                component={ToleranceQuestionnaire}
                flavor="advisor"
              />
            </Route>
          </Route>
        </Route>
        <Route path="investors" component={ClientsWrapper}>
          <IndexRoute component={AdvisorInvestors} type="client" />
          <Route path="create" component={CreateNewClient} />
          {/* investor details */}
          <Route path=":id">
            <Route component={InvestorsAccount}>
              <IndexRedirect to="overview" />
              <Route path="overview" component={InvestorOverview} />
              <Route path="check-ins" component={CheckIns} />
              <Route path="portfolio-insights" component={InvestorPortfolioInsights} />
              <Route path="prism" component={InvestorPrism} />
              <Route path="risk-tolerance" component={InvestorDetailsTolerance} />
              <Route path="fact-finder-forms" component={FactFinderForms} />
              <Route path="investment-objectives" component={InvestorsAccountList} />
              <Route path="proposals">
                <IndexRoute component={ProposalOrIpsReports} type={PROPOSAL_REPORT_TYPE} />
                <Route path="new" component={Proposal} type={INVESTOR_PROPOSAL_TYPE} />
                <Route path=":reportId" component={Proposal} type={INVESTOR_PROPOSAL_TYPE} />
              </Route>
              <Route path="ips">
                <IndexRoute component={ProposalOrIpsReports} type={IPS_REPORT_TYPE} />
                <Route path="new" component={IPS} />
                <Route path=":reportId" component={IPS} />
              </Route>
              <Route path="compliance-notes" component={InvestorDetailsNotes} />
            </Route>
            <Route path="target-rating(/:s)" component={ToleranceQuestionnaire} flavor="advisor" />
            <Route path="check-in(/:s)" component={CheckInQuestionnaire} flavor="advisor" />
            <Route path="account/:accountId">
              <Route component={AccountDetails}>
                <IndexRedirect to="overview" />
                <Route path="overview" component={AccountDetailsOverview} />
                <Route path="prism" component={AccountDetailsPrism} />
                <Route path="risk-tolerance" component={AccountDetailsTolerance} />
              </Route>
              <Route
                path="target-rating(/:s)"
                component={ToleranceQuestionnaire}
                flavor="advisor"
              />
            </Route>
          </Route>
        </Route>
        <Route path="models">
          <IndexRoute component={AdvisorModels} />
          <Route path=":id">
            <Route component={AdvisorModelsDetail}>
              <IndexRedirect to="overview" />
              <Route path="overview" component={AdvisorModelsDetailsOverview} />
              <Route path="risk-analysis" component={RiskAnalysis} />
              <Route path="performance-analysis" component={Proposal} type={MODEL_PROPOSAL_TYPE} />
              <Route path="strategy" component={AdvisorModelStrategy} type="model" />
              <Route path="access-control" component={AdvisorModelAccessControl} type="model" />
            </Route>
          </Route>
        </Route>
        <Route component={StandardContainer}>
          <Route
            path="marketplace"
            onEnter={onEnterHooks(
              isComplianceOrAbove(
                store,
                hasPermission(store, 'compliance', '/advisor'),
                hasCompanySettings(
                  store,
                  'allow_advisors_models_marketplace_access',
                  true,
                  '/advisor'
                )
              )
            )}
          >
            <IndexRoute component={ModelsMarketplace} />
            <Route path=":id">
              <Route component={MarketplaceDetail}>
                <IndexRedirect to="about" />
                <Route path="about" component={MarketplaceDetailAbout} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route component={StandardContainer}>
          <Route
            path="securities"
            onEnter={onEnterHooks(
              isComplianceOrAbove(
                store,
                hasPermission(store, 'compliance', '/advisor'),
                hasCompanySettings(
                  store,
                  'allow_advisors_custom_securities_access',
                  true,
                  '/advisor'
                )
              )
            )}
          >
            <IndexRoute component={CustomSecurityList} />
            <Route path="create" component={CustomSecurityCreate} />
            <Route path="bulk" component={CustomSecurityBulkTable} />
            <Route path=":id">
              <Route component={CustomSecurityDetail}>
                <IndexRedirect to="overview" />
                <Route path="overview" component={SecurityOverview} />
                <Route path="access-control" component={SecurityAccessControl} />
                <Route path="returns" component={SecurityReturns} />
                <Route path="returns-risk" component={SecurityRiskReturns} />
                <Route
                  path="performance-analysis"
                  component={Proposal}
                  type={SECURITY_PROPOSAL_TYPE}
                />
                <Route path="underlying-holding" component={SecurityUnderlyingHolding} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route component={StandardContainer}>
          <Route
            path="ai-assistant"
            onEnter={onEnterHooks(
              isComplianceOrAbove(
                store,
                hasPermission(store, 'compliance', '/advisor'),
                hasCompanySettings(store, 'allow_advisors_scan_iq_access', true, '/advisor')
              )
            )}
          >
            <IndexRoute component={AIAssistant} />
            <Route
              path="scan-iq"
              onEnter={onEnterHooks(
                isComplianceOrAbove(
                  store,
                  hasPermission(store, 'compliance', '/advisor'),
                  hasCompanySettings(store, 'allow_advisors_scan_iq_access', true, '/advisor')
                ) && hasCompanySettings(store, 'scan_iq_enabled', true, '/advisor')
              )}
            >
              <IndexRoute component={ScanIQ} />
              <Route path="history" component={ScanHistory} />
              <Route path=":id" component={ScanDetails} />
            </Route>
          </Route>
        </Route>
        <Route
          path="accounts"
          onEnter={onEnterHooks(
            hasCompanySettings(store, 'integrations_enabled', true, '/advisor')
          )}
        >
          <IndexRoute component={IntegrationAccounts} />
          <Route path="by-risk-level" component={AccountsByRiskLevel} />
        </Route>
        <Route
          path="surveillance"
          component={ComplianceSurveillance}
          onEnter={onEnterHooks(hasPermission(store, 'compliance', '/advisor'))}
        />
        <Route path="drift-exceptions" component={DriftExceptions} />
      </Route>

      {/* Investor */}
      <Route path="investor" component={Investor}>
        <Route path="plaid/callback" component={ConnectAccount} mode="investor" />
        <Route path=":id">
          <IndexRedirect to="target-rating" />
          <Route path="target-rating(/:s)" component={ToleranceQuestionnaire} flavor="investor" />
          <Route path="check-in(/:s)" component={CheckInQuestionnaire} flavor="investor" />
          <Route path="accounts/:accountId">
            <IndexRedirect to="target-rating" />
            <Route path="target-rating(/:s)" component={ToleranceQuestionnaire} flavor="investor" />
          </Route>
          <Route path="connect" component={ConnectAccount} mode="investor" />
          {/* legacy URLs */}
          <Route path=":legacyToken/*" component={InvestorSessionRedirect} />
        </Route>
      </Route>

      {/* Guest */}
      <Route path="inapp-view/b/:advisorId/:widgetToken">
        <IndexRoute component={MarketingBanner} />
        <Route path="target-rating/" component={PrismQuestionsGuest} />
      </Route>
      <Route path="signature-request" component={SignatureRequest} />

      <Route path="*">
        <IndexRedirect to="/advisor" />
      </Route>
    </Route>
  </Router>
);

AppRouter.propTypes = {
  history: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired
};

export default React.memo(AppRouter);
